<template>
  <div class="col aic">
    <img
      src="http://huaxiayong.oss-cn-beijing.aliyuncs.com/2022/12/27/509b93a90598403fb11c389b13ac105d.png"
      alt=""
    />
    <div>请点击下方
     <span style=" color: #f85959;">使用完整服务</span> 
       授权信息继续访问</div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
