<template>
  <div class="col aic">
    <img
      src="http://huaxiayongdao.oss-cn-beijing.aliyuncs.com/2022/02/11/1f35aa41db874880a8c785568d347d05.png"
      alt=""
    />
    <div>绑定失败，该微信已绑定账号</div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
